<template>
  <v-card min-width="680">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card-title>B2B Policy Details</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-form>
            <v-text-field
              v-model="form.policy_name"
              label="Policy Name"
              :rules="policy_name_rules"
              :counter="255"
            />
            <v-text-field
              v-model="form.policy_description"
              label="Policy Description"
              :rules="policy_description_rules"
              :counter="255"
            />
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            outlined
            color="color_green"
            :disabled="checkForm"
            @click="confirmPolicy"
          >
            Confirm api policy details
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            :disabled="disableWSCard || !form.policy_name"
            outlined
          >
            <v-toolbar
              flat
              dark
              color="color_green"
            >
              <v-toolbar-title>Workspaces</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              />
              <v-spacer />
              <cu-workspace-dialog
                :key="componentKey"
                :type="'new'"
                @closeDialogEvent="forceRerender"
              />
            </v-toolbar>
            <v-virtual-scroll
              :items="geoserver_data.workspaces"
              bench="5"
              max-height="300"
              item-height="64"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item.workspace_name">
                  <v-list-item-content>
                    <v-list-item-title>
                      Workspace: {{ item.workspace_name }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn-toggle>
                      <cu-workspace-dialog
                        :key="componentKey"
                        :ws="item"
                        @closeDialogEvent="forceRerender"
                      />
                      <v-btn icon>
                        <delete
                          :id="item.name"
                          @deleted="deleteClick(item)"
                        />
                      </v-btn>
                    </v-btn-toggle>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, manageLenghtError } from '@/formRules.js';

export default {
  components: {
    cuWorkspaceDialog: () => import('@/components/policy/CUWorkspaceDialog'),
    delete: () => import('@/components/utils/Delete')
  },
  props: {
    form: {
      type: Object,
      default: null
    },
    disableWScard: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      overlay: false,
      componentKey: 0,
      policy_name_rules: [...required, ...manageLenghtError(255)],
      policy_description_rules: [...manageLenghtError(255)],
      disableWSCard: false
    };
  },
  computed: {
    ...mapState(['policy', 'geoserver_data']),
    checkForm() {
      const charLength = this.form.policy_name.length <= 0 || this.form.policy_name.length > 255;

      return charLength;
    }
  },
  watch: {
    form() {
      if (this.policy.isModifingPolicy == true) {
        this.storeWorkspacesByPolicy(this.form.policy_id);
      } else {
        this.geoserver_data.workspaces = [];
      }
    }
  },
  created() {
    this.overlay = true;

    if (this.policy.isModifingPolicy == true) {
      this.storeWorkspacesByPolicy(this.form.policy_id);
      this.disableWSCard = false;
    } else {
      this.disableWSCard = true;
    }

    this.overlay = false;
  },
  methods: {
    ...mapActions([
      'storeNewPolicy',
      'storeUpdatedPolicy',
      'storeWorkspacesByPolicy',
      'storeWorkspace',
      'deleteScrollWorkspace',
      'storeIsModifingPolicy',
      'storeToastMessage'
    ]),
    async confirmPolicy() {
      this.overlay = true;

      if (this.policy.isModifingPolicy) {
        await this.storeUpdatedPolicy(this.form);
      } else {
        await this.storeNewPolicy(this.form);
        this.$emit('updateConfirmedPolicy');
        this.disableWSCard = false;
      }

      this.overlay = false;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    deleteClick(ws) {
      const wsToDelete = {
        workspace_name: ws.workspace_name,
        policy_id: this.form.policy_id
      };
      this.deleteScrollWorkspace(wsToDelete);
    }
  }
};
</script>
